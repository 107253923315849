import { Component, Input, Output, EventEmitter, OnChanges, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { Organization } from '@smartobjx/smart.objx.models';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";
import Mediator from '../core-services/mediator/structures-adm.mediator';
import { Mediator as MediatorMarket } from '../core-services/mediator/market-utilities.mediator';

// const isOfType = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);
// const isFile = (name: string) => name.split('.').length > 1;

const getRandomColor = () => '#' + (Math.random() * 0xFFFFFF << 0).toString(16);

@Component({
  selector: 'structure-branch-editor-cdk',
  templateUrl: './structure-branch-editor-cdk.component.html',
  styleUrls: ['./structure-branch-editor-cdk.component.scss']
})

export class StructureBranchEditorCdkComponent implements OnChanges {
  @ViewChild("extradataEl", { static: false }) extraDataElement: any;
  @ViewChild("moreinfo", { static: false }) moreinfoElement: any;
  ViewPermisionsCustomer: { Name: string; }[];
  ViewPermisionsPartner: any;

  reloadPermissions(org: any) {
    let item = null
    findUnit(this.branch.Units);
    function findUnit(units) {
      for (let index = 0; index < units.length; index++) {
        if (units[index].Organization.OID == org.Organization.OID) {
          item = units[index];
          break
        }
        else {
          findUnit(units[index].Units)
        }
      }
    }
    if(!_.isNil(item)){
      this.loadAccessors(item)
    }
  }

  ngOnChanges(changes: any) {
    if (changes.branch) {
      this.collapseElement(this.extraDataElement);
      this.collapseElement(this.moreinfoElement);
    }
    // if(changes.branch && changes.branch.currentValue){
    // }
  }
  private collapseElement(el: any) {
    if (el) el.nativeElement.expanded = false;
  }

  constructor(private renderer: Renderer2, private mediator: Mediator, private marketMediator: MediatorMarket) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  @Input() zoom: number;
  @Input() branch: any;
  @Input() organizationsInStructure: Organization[];

  @Output() itemMovedOrTransfered = new EventEmitter();

  evenPredicate(item: CdkDrag<number>) {
    // console.log(item.data);
    return true;
  }
  stringify(sourceItem: any) {
    return sourceItem ? JSON.stringify(sourceItem.item.dataItem.color) : "none";
    // return JSON.stringify(sourceItem);
  }

  cdkDragStarted(event: any, item: any) {
    this.dragged = item;
  }
  cdkDragReleased(event: any) {
    this.dragged = undefined;
  }
  dragged: any;
  isDragged(item: any) {
    return this.dragged === item;
  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }
  onDragEnded(event: any) {
    let element = event.source.getRootElement();
    let boundingClientRect = element.getBoundingClientRect();
    let parentPosition = this.getPosition(element);
    console.log('x: ' + (boundingClientRect.x - parentPosition.left), 'y: ' + (boundingClientRect.y - parentPosition.top));
  }
  drop(event: CdkDragDrop<number[]>) {
    const newParent = event.container.data as any;
    if (newParent.Organization.IsPartner) return;

    let { item, parent, isNew } = event.item.data;
    if (isNew) {
      item = {
        Color: "#5550b3",
        Organization: item,
        Partners: [],
        Tag: "New",
        Units: []
      };
    }
    // const { offsetLeft: itemOffsetLeft, offsetTop: itemOffsetTop } = event.item.element.nativeElement;
    // const { offsetLeft: containerOffsetLeft, offsetTop: containerOffsetTop } = event.container.element.nativeElement as any;
    // console.log(itemOffsetLeft, itemOffsetTop);
    // console.log(containerOffsetLeft, containerOffsetTop);
    // console.log(item);
    // console.log(this.dragged);
    // console.log(event.container.data);
    if (this.isSameOrDescendant(newParent, item)) {
      // console.log('drop fail - isSameOrDescendant');
      return;
    }
    const info = this.getColorInfoByItem(newParent, 1);
    this.setColorToBranchWithInfo(item, info);

    if (parent) {
      const i = parent.Units.indexOf(item);
      parent.Units.splice(i, 1);
    }

    newParent.Units.unshift(item);
    // if (event.previousContainer === event.container) {
    //   moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // } else {
    //   transferArrayItem(event.previousContainer.data,
    //                     event.container.data,
    //                     event.previousIndex,
    //                     event.currentIndex);
    // }
    this.itemMovedOrTransfered.emit();
  }
  entered(event: any) {
    console.log(event);
  }

  isSameOrDescendant(item: any, ancestor: any = this.dragged) {
    return item === ancestor || (ancestor && this.isDescendant(item, ancestor));
  }

  private isDescendant(item: any, ancestor: any): boolean {
    if (!!~ancestor.Units.indexOf(item))
      return true;

    if (!ancestor.Units.length)
      return false;

    for (const o of ancestor.Units) {
      if (this.isDescendant(item, o))
        return true;
    }

    return false;
  }


  onDragOver(event: any, item: any, mode: string = 'vertical') {
    event.preventDefault();

    const el = event.currentTarget;
    const card = el.querySelector('mat-card');
    const list = card.classList;

    // this.clearStyles(el);

    // if(this.dragged === item || this.isDescendant(item, this.dragged)) {
    //   list.add('put-error');
    // } else {
    const perc = this.getDropActionPerc(event, mode);
    if (perc <= 20) {
      // this.putBefore( el );
      console.log('before');
    } else if (perc >= 80) {
      console.log('after');
      // this.putAfter( el );
    } else {
      console.log('in');
      // list.add('put-in');
    }
    // }
  }

  private getDropActionPerc(event: any, mode: string): number {
    return mode === 'none' ? 50 : mode === 'horizontal'
      ? Math.abs(event.offsetX) * 100 / event.target.offsetWidth // using dropzone
      : Math.abs(event.offsetY) * 100 / event.target.offsetHeight;
  }


  private countLevelByItem(item: any, units: any[], counter: number) {
    if (units.filter(o => o === item).length === 1) {
      return counter + 1;
    }
    for (const unit of units) {
      const count = this.countLevelByItem(item, unit.Units, counter + 1);
      if (count)
        return count;
    }
  }

  private getColorInfoByItem(item: any, fix: number = 0) {
    const colors = this.colorLevels;
    const level = this.countLevelByItem(item, [this.branch], -1 + fix);

    if (level != null && level < colors.length)
      return { color: colors[level], level };

    return { color: getRandomColor(), level };
  }

  toggleChild(el: any) {
    el.hide = !el.hide;
  }

  // private colorLevelChecker( level: any[], list: string[] ){
  //   const merged = [].concat.apply([], level.map(o => o.Units));
  //   if(merged.length){
  //     list.push(merged[0].Color);
  //     const withChildren = merged.filter(o => o.Units.length);
  //     if(withChildren.length){
  //       this.colorLevelChecker( withChildren, list );
  //     }
  //   }
  // }
  // @Input() colorLevels: string[];
  // @Input() colorLevelsForSelectedBranch: string[];
  // get colorLevels(): string[]{
  //   let list = ['red', 'green','blue'];
  //   if(this.branch){
  //     const subscriber = this.branch;
  //     list = [subscriber.Color || 'blue'];

  //     this.colorLevelChecker( [this.branch], list );
  //   }
  //   return list;
  // }

  private colorLevelChecker(level: any[], list: string[]) {
    const merged = [].concat.apply([], level.map(o => o.Units));
    if (merged.length) {
      list.push(merged[0].Color);
      const withChildren = merged.filter(o => o.Units.length);
      if (withChildren.length) {
        this.colorLevelChecker(withChildren, list);
      }
    }
  }
  get colorLevels(): string[] {
    let list = [];
    if (this.branch) {
      const top = this.selectedBranch;
      list = [top.Color];

      this.colorLevelChecker([this.selectedBranch], list);
    }
    return list;
  }


  private setColorToBranchWithInfo(item: any, info: any) {
    const colors = this.colorLevels;
    let level = info.level;

    item.Color = info.color;

    const merge = (list: any[]) => [].concat.apply([], list.map(o => o.Units));

    let list = merge([item]);
    while (list.length) {
      const color = level < colors.length - 1 ? colors[++level] : getRandomColor();
      list.forEach(o => o.Color = color);

      list = merge(list);
    }
  }
  editBranch(item: any) {
    const level = this.countLevelByItem(item, [this.branch], -1);
    // this.colorLevelsForSelectedBranch = this.colorLevels.slice( level );
    this.selectedBranch = item;
  }
  public closeEditBranch() {
    this.selectedBranch = null;
  }
  showOptions() {
    return !(this.parentNode == this.branch || this.mediator.rootlevel == this.branch)
  }

  selectedBranch: any;
  @Input() hierarchyInfo: string;
  getHierarchyFor(item: any) {
    const seeker = (parent: any) => {
      // const merged = [].concat.apply([], parent.map(o => o.Units));
      const units = parent.Units;
      if (units.length) {
        const index = units.indexOf(item);
        if (!!~index) {
          return parent.Organization.Name;
        } else {
          for (var child of units) {
            const resp = seeker(child);
            if (resp) {
              return parent.Organization.Name + ' > ' + resp;
            }
          }
        }
      }
    };
    if (this.hierarchyInfo) {
      return this.hierarchyInfo + ' > ' + seeker(this.branch);
    } else {
      return this.branch.Organization.Name;
    }
  }


  @Output() remove = new EventEmitter<any>();
  @Output() permissionFor = new EventEmitter<any>();
  @Output() newOrganization = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() closeAndRemove = new EventEmitter<any>();

  @Output() addChildAs = new EventEmitter<{ item: any, type: string, parent: any }>();
  @Output() addAsPartner = new EventEmitter<any>();

  @Input() availableOrganizations: any[];
  @Input() availablePartners: any[];
  @Input() parentNode: any;
  @Input() disableAddPartner: boolean;
  @Input() clientAPI: string;
  @Input() selectedPerspective
  asd(item: any) {
    console.log(item);
  }
  addChild(item: any, parent: any) {
    this.addChildAs.emit({ item, type: 'new', parent });
  }
  hasPartners(item: any) {
    return item && item.Partners && item.Partners.length;
  }

  @Input() permissions: any = {
    hide: false,
    manageDisabled: false,
    viewDisabled: false
  };

  toggleInfo(el: any, extradataEl: any, item) {
    if (this.permissions.viewDisabled) return;
    extradataEl.expanded = false;
    el.expanded = !el.expanded;
    if (el.expanded) {
      this.loadAccessors(item);
    }
  }


  private loadAccessors(item: any) {
    this.mediator.GetACLsForAccessee(item.Organization.OID, this.selectedPerspective.OID).subscribe(enableACL => {
      if (!_.isNil(this.branch.Partners) && this.branch.Partners.length > 0) {
        var partnerList = this.branch.Partners.map(partner => partner.Organization.OID);
        item.ViewPermisionsPartner = enableACL.filter(acl => partnerList.includes(acl.Accessor.OID)).map(acl => {
          if (acl.Access) return { Name: acl.Accessor.Name };
        }).filter(customerItem => customerItem);
        let customer = enableACL.filter(acl => !partnerList.includes(acl.Accessor.OID)).map(acl => {
          if (acl.Access) return { Name: acl.Accessor.Name };
        });
        item.ViewPermisionsCustomer = customer.filter(customerItem => customerItem);
      } else {
        let customer = enableACL.map(acl => {
          if (acl.Access) return { Name: acl.Accessor.Name };
        });
        item.ViewPermisionsCustomer = customer.filter(customerItem => customerItem);
      }
    });
  }

  extraDataKeys(el: any): string[] {
    if (!el.data) return [];
    return Object.keys(el.data);
  }
  extraDataName(el: any, key: string) {
    return el.data[key].name;
  }
  extraDataValue(el: any, key: string) {
    return el.data[key].value;
  }
  extraDataType(el: any, key: string) {
    return el.data[key].type;
  }

  // get extraDataObject() {
  //   return JSON.stringify(this.extraDataValues);
  // }

  getExtraDataTypeInfo(e: any, el: any) {
    el.loading = true;
    el.record = {};
    this.fetchExtraData(null, e.value)
      .then(data => {
        if (data.types) {
          el.types = data.types;
        }
        el.properties = data.properties;
        if (el.properties && el.properties.data) {
          Object.keys(el.properties.data).map(key => {
            el.record[key] = el.properties.data[key].value; // sets default values
          });
        }
        if (data.record) {
          el.record = data.record;
          el.type = data.record.type;
        }
        el.loading = false;
      })
      .catch(e => {
        console.log(e);
      });
  }
  showExtraData(el: any, moreinfoEl: any, oid: string, forceExpanded: boolean = false) {
    moreinfoEl.expanded = false;
    el.error = null;
    if (forceExpanded) {
      el.expanded = true;
    } else {
      el.expanded = !el.expanded;
    }
    if (el.expanded) {
      el.loading = true;
      this.fetchExtraData(oid)
        .then(data => {
          if (data.types) {
            el.types = data.types;
          }
          if (data.record) {
            el.record = data.record;
            el.type = data.record.type;
          }
          el.properties = data.properties;
          el.loading = false;
        })
        .catch(e => {
          el.loading = false;
          el.error = 'Something went wrong...';
        });
    } else {
    }
  }
  saveExtraData(el: any, organization: Organization) {
    el.loading = true;

    var data = Object.assign({}, el.record, { OID: (organization as any).OID, name: organization.Name, type: el.type });

    fetch(this.clientAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (!response.ok)
        throw { message: response.statusText };

      el.loading = false;
      el.expanded = false;
    })
  }
  private async fetchExtraData(oid: string, type: string = '') {
    var url = new URL(this.clientAPI)
    let params: any = { oid };
    if (type) {
      params['type'] = type;
    }
    url.search = new URLSearchParams(params).toString();
    const response = await fetch(url.toString());
    const config = await response.json();
    var data = JSON.parse(config);
    // console.log( data );
    return data;
  }

  doCloseAndRemove(item: any) {
    this.selectedBranch = null;
    this.remove.emit(item);
  }
}
