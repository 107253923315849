import { Component, OnInit, forwardRef } from '@angular/core';
import { MarketService, MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { Organization, Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';


let serviceConfig: MarketServiceConfiguration = new MarketServiceConfiguration({
  basePath: 'http://localhost/smart.Market.Service'
});

@Component({
  selector: 'app-login-portal',
  templateUrl: './login-portal.component.html',
  styleUrls: ['./login-portal.component.css']
})
export class LoginPortalComponent implements OnInit {

  pov: string;
  token: string;
  keep: boolean;
  demoSubscriptions: any[];
  selectedSubscription: any
  dataOwners: Organization[];
  constructor(private authService: AuthService,
    private mediator: Mediator,
    private route: ActivatedRoute,

    private _router: Router) { }

  ngOnInit() {

    this.route.queryParams
    .subscribe(params => {
      console.log(params);
      if (!_.isNil(params) && !_.isNil(params.token)) {
        this._router.navigate(
          ['/registerSubscription'],
          { queryParams: { offerMarket: params.token } }
        );
        //  this.mediator.ActiveMarketPlaceOffer(params.token).subscribe(token => {
        //    console.log(token)
        //  })
      }
    })

    this.mediator.GetDemoPerspectives("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
      .subscribe(subs => {
        this.demoSubscriptions = subs
        this.isLoadingTokens = false;
      })




  }

  setDefaultToken(event) {
    // this.token = '5f87bf0e89a8427aaa5a8f4c01ffd777';
  }


  tokenSelected() {
    this.token = this.selectedSubscription.AuthTokens[0].OID
    this.pov = this.selectedSubscription.OID
    this.isLoading = true;
    this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", this.selectedSubscription.AuthTokens[0].OID, this.selectedSubscription.OID)
      .subscribe(orgs => { this.dataOwners = orgs; this.isLoading = false; }, err => {
        if (err.status == 404) {
          this.isLoading = false;
        }
      })

  }
  login(event) {
    if (this.token != null && this.pov != null) {
      this.errors = '';
      this.authService.setTokenId(this.token);
      this.authService.setPOV(this.pov.toString());

      this.authService.userName = null;
      this.isLoading = true;
      this.mediator.checkLoginAndGetName(this.pov.toString(), this.token)
        .subscribe({
          next: (response: any) => {
            this.authService.userName = response.Subscriber;
          },
          error: e => {
            if (typeof e.error === 'string' && !!~e.error.indexOf('SubscriberID')) {
              this.errors = 'invalid token';
            } else {
              this.errors = 'server error';
            }
            // console.error(e);
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;

            if (this.keep) {
              localStorage.setItem('authToken', this.token);
              localStorage.setItem('authPov', this.pov.toString());
              localStorage.setItem('userName', this.authService.userName);
              localStorage.setItem('subscriberName', this.authService.subscriberName);
            }
            this._router.navigate(['asd']);
          }
        });
    }
  }

  isLoading: boolean = false;
  isLoadingTokens: boolean = true;
  errors: string = '';
  tokens: any = [];
}
