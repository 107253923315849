import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';
// import { SmartUxLibModule } from 'smart-ux';

import { StandardModule } from '../standard/standard.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // SmartUxLibModule,
    StandardModule,
    
    // RuleSetRoutedRoutingModule
  ],
  declarations: [
    // RuleSetRoutedComponent,
  ]
})
export class StructuresAdmModule {}