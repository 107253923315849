import { Injectable } from '@angular/core';
import { SettingsService, StructuresService } from '@smartobjx/smart.connectors';
import { environment } from 'src/environments/environment';
import { AuthService } from '../authentication/auth.service';

export function factory() {
  return (
    _server: StructuresService, _settingServer: SettingsService , _authService:AuthService
  ): Mediator => {
    return new Mediator(_server, _settingServer,_authService);
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {

  constructor(
    private server: StructuresService, private settingServer: SettingsService,private _authService:AuthService
  ) { }

  checkLoginAndGetName(ownerId: string, subscriberToken: string) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.checkLoginAndGetName(ownerId);
  }


  GetDemoPerspectives(ownerId: string, subscriberToken: string) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.getLineageForDemo()
  }


  
  GetNestedClients(ownerId: string, subscriberToken: string , perspectiveId) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.findNestedClients(perspectiveId)
  }

  ActiveMarketPlaceOffer(token, companyName) {


    let headerRequest = {}
    headerRequest['Content-Type'] = "application/json"
    let body= {marketOffer:token,companyName:companyName}
    return this._authService.httpClient.post(`${environment.SubcriptionService}/ResolveStructuresOffer/`,body,
      {
        withCredentials: null,
        headers: headerRequest,
      })
  }
}