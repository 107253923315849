import { NgModule }       from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// #region material
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// #endregion
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PerspectiveDrawerComponent } from '../perspective-drawer/perspective-drawer.component'
import { PermissionDrawerComponent } from '../permission-drawer/permission-drawer.component'
import { PerspectiveListComponent } from '../perspective-list/perspective-list.component'
import { StructureElementComponent } from '../structure-element/structure-element.component'
import { StructureBranchEditorCdkComponent } from '../structure-branch-editor-cdk/structure-branch-editor-cdk.component'
import { StructuresAdmComponent } from '../structures-adm/structures-adm.component'
import { AddOrganizationDialogComponent } from '../add-organization-dialog/add-organization-dialog.component';
import { ConfigVersionsComponent } from '../config-versions/config-versions.component';
import { VersionCaptionComponent } from '../version-caption/version-caption.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SmartComponentsModule } from '@smartobjx/smart.components';
const routes: Routes = [];
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        DragDropModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatDatepickerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatSelectModule,
        MatTableModule,
        MatTreeModule,
        MatRadioModule,
        MatGridListModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSidenavModule,
        MatChipsModule,
        MatSliderModule,
        MatSlideToggleModule,
        SmartComponentsModule,
        RouterModule.forRoot(routes, {}),
    ],
    declarations: [
        SimpleDialogComponent,
        PerspectiveDrawerComponent,
        PermissionDrawerComponent,
        PerspectiveListComponent,
        StructureElementComponent,
        StructureBranchEditorCdkComponent,
        StructuresAdmComponent,
        AddOrganizationDialogComponent,
        ConfigVersionsComponent,
        VersionCaptionComponent,
       // SmartCustomDatepickerComponent
    ],
    providers: []
})
export class StandardModule {}