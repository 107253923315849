<mat-drawer-container class="simple-layout" [hasBackdrop]="true">
  <mat-drawer-content cdkDropListGroup>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    <mat-drawer-container class="inner-layout" [hasBackdrop]="false">
      <mat-drawer mode="side" [(opened)]="drawerState">
          <perspective-drawer
              [isLoading]="loadingOrganizations"

              [organizations]="organizations"
              [partners]="partners"
              [organizationsInStructure]="organizationsInStructure"
              [partnersInStructure]="partnersInStructure"
              (addOrganization)="addOrganizationFromDrawer()"
              (editOrganization)="addOrganizationFromDrawer( $event )"
              (deleteOrganization)="deleteOrganization( $event )"
              (addPartner)="addOrganizationFromDrawer( null, true )"
              (editPartner)="addOrganizationFromDrawer( $event, true )"
              (deletePartner)="deleteOrganization( $event )"
              (addPartnerToPerspective)="addPartnerToPerspective( $event.item )"
              (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )"
              [perspectives]="perspectives"
              [selectedPerspective]="selectedPerspective"
              (changePerspective)="setPerspectivebyName( $event )"
              (removePerspective)="removePerspective(null)"
              (addPerspective)="addPerspective()"
              (savePerspective)="savePerspective()"

              (configPerspective)="configPerspective(perspectiveConfig)"
              
              (addChild)="addChild( $event.item, $event.parent )"
              (removeItem)="removeFromGraph( { name: $event.Name } )"
              
              (draggingItemChange)="draggingItem = $event"
          ></perspective-drawer>
      </mat-drawer>
      
      <mat-drawer-content class="main-drawer-content">
        <ng-template #drawerContent>
          <mat-slider max="1.5" min="0.1" step="0.1" ><input matSliderThumb [(ngModel)]="zoomLevel"></mat-slider>
          <mat-progress-bar *ngIf="perspectivesAreLoading" mode="indeterminate"></mat-progress-bar>
          <structure-branch-editor-cdk [zoom]="zoom"
            *ngIf="selectedPerspective && selectedPerspective.Tree"
            [branch]="selectedPerspective.Tree"
            [selectedPerspective]="selectedPerspective"
            [organizationsInStructure]="organizationsInStructure"
            (itemMovedOrTransfered)="updateStructure()"
            (remove)="removeFromGraph( { name: $event.Organization.Name } )"
            [availableOrganizations]="availableOrganizations"
            [availablePartners]="availablePartners"
            (newOrganization)="createOrganizationThenAdd( $event.item, $event.isPartner )"
            (permissionFor)="permissionFor( $event );"
            (addChildAs)="addChildAs( $event.item, $event.type, $event.parent )"
            (addAsPartner)="addPartnerToPerspective( $event )"
            [parentNode]="parentNode"
            [clientAPI]="selectedPerspective.ClientAPI"
          ></structure-branch-editor-cdk>
        </ng-template>
        <ng-container *ngTemplateOutlet="drawerContent"></ng-container>
      </mat-drawer-content>
      
      <!-- placeholder for the first element -->
      <mat-drawer-content class="main-drawer-content" *ngIf="selectedPerspective && selectedPerspective.Tree == null"
        cdkDropList
        (cdkDropListDropped)="addChild( $event.item.data.item )"
      >
      </mat-drawer-content>

    </mat-drawer-container>
  </mat-drawer-content>
  
  <mat-drawer mode="over" [(opened)]="showPermissions" position="end">
    <permission-drawer [organization]="selectedOrganization" [items]="customersForMarket" (close)="closePermission()"></permission-drawer>
  </mat-drawer>
</mat-drawer-container>


<ng-template #perspectiveConfig>
  <div class="h6" matDialogTitle>{{ this.selectedPerspective.Name }} configurations</div>
  <mat-dialog-content>
    <mat-form-field class="disabled-but-looks-enabled" style="width: 600px;">
      <input matInput placeholder="Client API" [(ngModel)]="this.editingClientAPI" />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>close</button>
    <button mat-raised-button matDialogClose color="primary" (click)="setClientAPI()">Set</button>
  </mat-dialog-actions>
</ng-template>
