<div [ngStyle]="{ position: 'relative' }">
  <div [class.demogramonlychild]="isDemogramOnlyChild" [class.lastchild]="lastChild"></div>
 
  <mat-card
    [class.connector]="useDendogramDot" [class.firstpartner]="firstPartner"
    [class.treeitem]="isTreeItem"
    [class.treefirstchild]="isTreeFirstChild" [class.onlychild]="onlyChild"
    [class.treelastchild]="isTreeLastChild"
  >
    <div [class.dendogramdot]="useDendogramDot" [class.treedot]="useTreeDot"></div>
    
    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="options" class="option-button">
      <mat-icon>more_vert</mat-icon>
      
      <mat-menu #menu>
        <button mat-menu-item [matMenuTriggerFor]="editMenu">Edit</button>
        <ng-container *ngFor="let item of menuItems; let firstItem = first;">
          <!-- <mat-divider *ngIf="!firstItem"></mat-divider> -->
          <button *ngIf="useCustomMenu( item.type ); else nomenu"
            mat-menu-item (click)="actionByType( item )"
            [matMenuTriggerFor]="customMenu"
            [matMenuTriggerData]="{orgType: item.type}"
          >{{ item.name }}</button>
          <ng-template #nomenu>
            <button
              mat-menu-item (click)="actionByType( item )"
            >{{ item.name }}</button>
          </ng-template>
        </ng-container>
      </mat-menu>
            
      <mat-menu #customMenu="matMenu">
        <ng-template matMenuContent let-action="orgType">
          <button mat-menu-item (click)="newItem( action )" >+ Add new</button>
          <button mat-menu-item *ngFor="let item of (action == 'addPartner' ? availablePartners : availableOrganizations);" (click)="actionByTypeFor( item, action )">{{ item.Name }}</button>
        </ng-template>
      </mat-menu>
      
      <mat-menu #editMenu="matMenu">
        <!-- <button mat-menu-item [matMenuTriggerFor]="editColorMenu">Color</button> -->
        <button mat-menu-item (click)="editBranch.emit()">Branch</button>
      </mat-menu>
      <!-- <mat-menu #editColorMenu="matMenu">
        <button mat-menu-item *ngFor="let color of colors;" (click)="changeColor.emit( color.value )" [style.backgroundColor]="color.value" [title]="color.name"></button>
      </mat-menu> -->
    </button>
    
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle class="subtitle" [style.color]="color ? color : undefined" >{{ subtitle }}</mat-card-subtitle><!-- (dblclick)="changeSubtitle.emit()" -->
    
    <ng-container *ngIf="!permissions.hide">
      <div class="actions">
        <span></span>
        <span [class.disabled]="permissions.manageDisabled" (click)="permissionFor.emit()">Manage permissions</span>
        <span class="divider"></span>
        <span [class.disabled]="permissions.viewDisabled" (click)="toggleInfo( moreinfo );" #moreinfo>
          {{ moreinfo.expanded ? 'Hide' : 'View'}} permissions 
          <mat-icon *ngIf="!moreinfo.expanded">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="moreinfo.expanded">keyboard_arrow_up</mat-icon>
        </span>
      </div>

      <div *ngIf="!permissions.viewDisabled && moreinfo.expanded" class="more-info">
        <div>
          <div class="partners">Partners:</div>
          <div>items</div>
        </div>
        <div>
          <div class="customers">Customers:</div>
          <div>items</div>
        </div>
      </div>
    </ng-container>

    <div
      class="border-identifier"
      [style.backgroundColor]="color ? color : undefined"
      title="double click to change the color level"
      (dblclick)="changeColor.emit()"
    ></div>
  </mat-card>
</div>
