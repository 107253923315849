import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
// import Mediator from '../core-services/mediator/fixes.mediator';
import { Title } from '@angular/platform-browser';
import { Organization, Perspective } from '@smartobjx/smart.objx.models';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AuthService } from '../core-services/authentication/auth.service';
import Mediator from '../core-services/mediator/structures-adm.mediator';
import { CustomValidator } from '../shared/Validation';

@Component( {
    selector: 'perspective-drawer',
    templateUrl: './perspective-drawer.component.html',
    styleUrls: [ './perspective-drawer.component.scss' ]
} )

export class PerspectiveDrawerComponent implements OnChanges
{
  firstVersionsDate: Date;


  
  sort( list: Organization[] ){
    // const list = this.items.organizations;
    if(list.length > 1){
      if(!!~list[0].Name.localeCompare(list[1].Name)){
        this.sortAlphabetically(list, true);
      } else {
        this.sortAlphabetically(list, false);
      }
    }
  }
  private sortAlphabetically( list: any[], fromAtoZ: boolean ){
    if( fromAtoZ ){
      list.sort((a, b) => a.Name.localeCompare(b.Name));
    } else {
      list.sort((a, b) => b.Name.localeCompare(a.Name));
    }
  }

  filterOrganizations( filterState ) {
    // const state = this.filterStateOrganizations;
    // console.log( filterState );
    const all = filterState.included === filterState.notIncluded;
    this.filterStateOrganizations = all ? 'all' : filterState.included ? 'added' : 'notadded';
  }
  filterPartners( filterState ) {
    // const state = this.filterStatePartners;
    const all = filterState.included === filterState.notIncluded;
    this.filterStatePartners = all ? 'all' : filterState.included ? 'added' : 'notadded';
    // this.filterStatePartners = state == 'all' ? 'notadded' : state == 'notadded' ? 'added' : 'all';
  }

  private getRandomName(){
    return Math.random().toString(36).replace(/[^a-z)]+/g, '').substr(0, Math.floor(Math.random() * 30) + 6);
  }
  private getAsOrgList( list: string[] ){
    return list.map(name => {
      return {
        Name: name
      }
    })
  }

  constructor (
      // mediator: Mediator,
      title: Title,
      private authSvc: AuthService,
      private mediator: Mediator, 
  )
  {
    this.items.organizations = [];
    // this.items.organizations = this.getAsOrgList(['Real Page', 'Intuit', 'National Grid', 'Nowcom', 'ACCOUNTANTS ONE, INC', 'Athletes\' Performance Inc.', 'Aporeto', 'Campus Living Villages Pty Limited']);
    this.items.partners = [];
    // this.items.partners = this.getAsOrgList(['Sterling', 'Good Hire', 'Accurate Now']);
    // for(let i = 0; i < 28; i++){
    //   this.items.partners.push(this.getRandomName());
    // }
      // .concat(new Array(28).fill(this.getRandomName()));
  }
  ngOnChanges( changes: any ){
    if( changes.organizations ){
      this.items.organizations = changes.organizations.currentValue;
      if(this.items.organizations && this.items.organizations.length){
        this.sortAlphabetically(this.items.organizations, true);
      }
    }
    if( changes.partners && !changes.partners.firstChange){
      this.items.partners = changes.partners.currentValue;
      this.sortAlphabetically(this.items.partners, true);
    }
  }

  items: any = {};
  get perspectiveName(): string{
    return this.selectedPerspective ? this.selectedPerspective.Name : null;
  }
  get organizationsFilteredIn(): Organization[] {
    return this.filterList( this.items.organizations, this.organizationsInStructure, this.filterStateOrganizations );
  }
  get organizationsFilteredOut(): Organization[] {
    return this.filterListOut( this.items.organizations, this.organizationsInStructure, this.filterStateOrganizations );
  }
  get partnersFilteredIn(): Organization[] {
    return this.filterList( this.items.partners, this.partnersInStructure, this.filterStatePartners );
  }
  get partnersFilteredOut(): Organization[] {
    return this.filterListOut( this.items.partners, this.partnersInStructure, this.filterStatePartners );
  }

  private filterList( list: Organization[], itemsInStructure: Organization[], state: string ){
    if(typeof list === 'undefined') return [];
    switch( state ){
      case 'all':
        return list;
      case 'added':
        return list.filter(o => this.isInList( itemsInStructure, o) );
      case 'notadded':
        return list.filter(o => !this.isInList( itemsInStructure, o) );
    }
  }
  private filterListOut( list: Organization[], itemsInStructure: Organization[], state: string ){
    if(typeof list === 'undefined') return [];
    switch( state ){
      case 'all':
        return [];
      case 'added':
        return list.filter(o => !this.isInList( itemsInStructure, o) );
      case 'notadded':
        return list.filter(o => this.isInList( itemsInStructure, o) );
    }
  }
  
  isInList( list: Organization[], item: Organization ) {
    return list.length
      && list.filter(o => o.Name === item.Name ).length;
  }

  get subscriberName(): string {
    return this.authSvc.getSubscriberName();
  }
  public filterStateOrganizations: string = 'all';
  filterStatePartners: string = 'all';

  @Input() organizations: Organization[] = [];
  @Input() organizationsInStructure: Organization[] = [];

  private getOrganizationsFromStructure( el: any ): Organization[] {
      return [].concat.apply([el.Organization], el.Units.map(o => this.getOrganizationsFromStructure(o) ));
  }
  get organizationsInStructures(): any[]{
    if (!this.perspectives) return [];
    return this.perspectives.map( p => p.Tree ? this.getOrganizationsFromStructure( p.Tree ) : [] );
  }
  get partnersInStructures(): any[]{
    if (!this.perspectives) return [];
    return this.perspectives.map( p => p.Tree && p.Tree.Partners ? p.Tree.Partners.map( o => o.Organization ) : [] );
  }
  onChangeSelectedDate(perspective: Perspective, date: Date) {
    this.onVersions.emit({ record: this.selectedPerspective, date, versionDates: this.versionDates });
  }versionsIsLoading
  getVersions() {
    this.versionsIsLoading = true;
    this.mediator.FindConfigVersions(this.selectedPerspective.OID)
      .subscribe((data: any) => {
        this.versionsIsLoading = false;
        this.versionDates = data.map((o: any) => CustomValidator.ensureDate(o.Version));
        if (this.versionDates.length) {
          this.firstVersionsDate = this.versionDates[0];
        }
      });
  }

  selectedDate: Date = new Date();
  versionDates: Date[] = [];
  @Input() partners: Organization[] = [];
  @Input() partnersInStructure: Organization[] = [];

  @Input() perspectives: any[] = [];
  @Input() selectedPerspective: any;
  
  @Input() isLoading: boolean;

  @Output() configPerspective = new EventEmitter();
  
  @Output() changePerspective = new EventEmitter<any>();
  @Output() removePerspective = new EventEmitter();
  @Output() addPerspective = new EventEmitter();
  @Output() savePerspective = new EventEmitter();

  @Output() addOrganization = new EventEmitter();
  @Output() editOrganization = new EventEmitter<any>();
  @Output() deleteOrganization = new EventEmitter<any>();
  
  @Output() addPartner = new EventEmitter();
  @Output() editPartner = new EventEmitter<any>();
  @Output() deletePartner = new EventEmitter<any>();

  @Output() addChild = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<any>();
  
  @Output() addPartnerToPerspective = new EventEmitter<any>();
  @Output() onVersions = new EventEmitter<{ record: any, date: Date, versionDates: any[] }>();

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  @Output() draggingItemChange = new EventEmitter<any>();
}