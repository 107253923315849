import { Injectable } from '@angular/core';
import { MarketService, StructuresService } from '@smartobjx/smart.connectors';
import { AccessControlEntry, Organization, Perspective } from '@smartobjx/smart.objx.models';
import { AuthService } from '../authentication/auth.service';
import { Observable, Subject, of } from 'rxjs';
import { tap, concatMap, map } from 'rxjs/operators';
import * as moment from "moment";

import * as Guid from 'uuid/v4';
import { CustomValidator } from 'src/app/shared/Validation';
import { Tools } from 'src/app/shared/Tools';
export function factory() {
  return (_server: StructuresService,_marketserver :MarketService, _auth: AuthService): Mediator => {
    return new Mediator(_server,_marketserver, _auth);
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {
  reloadPerspective:Subject<any>
  marketserver: MarketService;
  showPerspectiveVersions(record: any, date: Date, versionDates: any[], callback: () => any) {
    const observable = new Observable(subscriber => {
      this.findPerspectiveOn(record.OID, date).toPromise();
    });

    observable.subscribe({
      error: e => console.error(e),
      complete: callback
    })
  }

  GetACLsForAccessee(owner:Guid,perspective:Guid): Observable<Array<AccessControlEntry>> {
    return this.marketserver.getACLsForAccesse(owner, perspective);
  }
  findPerspectiveOn(configID: any, date: Date): Observable<Perspective> {
    date = CustomValidator.fixDate(date);
    date = CustomValidator.fixSecondstoEnd(date);
    let offset = moment.parseZone(date).utcOffset();
    date = moment
      .utc(date)
      .add(-1 * offset, "minutes")
      .format() as any;
    return this.server.findPerspectiveOn(configID.OID, date)

  }

  rootlevel: any;

  constructor(_server: any,_marketserver:MarketService, private auth: AuthService) {
    this.server = _server; // fix compile duplicate 
    this.marketserver = _marketserver
  }
  private server: StructuresService;

  deleteOrganization(data: Organization): Promise<any> {
    return this.server.deleteOrganizationWith(data.OID, new Date()).toPromise();
  }
  saveOrganization(data: Organization): Observable<any> {
    return this.server.saveOrganization(null,data);
  }

  getOrganizations(): Observable<Organization[]> {
    // const orgs = this.tempData.organizations;
    // return of( Object.keys(orgs).map(k => new Organization(orgs[k]) ) );
    return this.server.getAllOrganizations();
  }

  deletePerspective(perspective: Perspective): Promise<any> {
    return this.server.deletePerspective(perspective.OID).toPromise();
  }
  // getPerspectives(): Observable<Perspective[]> {
  newPerspective(name: string): Perspective {
    return new Perspective({
      "Name": name,
      "Tree": null
    });
  }

  FindConfigVersions(perspectiveId: string): Observable<any> {
    this.server.configuration.POVToken = this.auth.getPOV();
    this.server.configuration.SubscriberToken = this.auth.getTokenId();
    return this.server.findPerspectivesVersions(perspectiveId);
  }


  // getPerspectiveLatest(): Observable<Perspective[]> {
  //   return this.server.();
  // }

  FindPerspectiveListNames(): Observable<any> {
    return this.server.findPerspectiveListNames();
  }

  FindPerspectiveWithName(name): Observable<Perspective> {
    return this.server.findPerspectiveWithName(name);
  }


  getPerspectives(): Observable<Perspective[]> {
    return this.server.getAllPerspectives();
    /*let list = [
      {
        "Name": "test",
        "Tree": {
          "Organization": this.tempData.organizations.test,
          "Tag": "subscriber",
          "Units": []
        },
        "Id": "3623475d-1f55-4392-8abc-8744d19770bf|7/9/2020 17:52:54",
        "OID": "3623475d-1f55-4392-8abc-8744d19770bf",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      }, 
      {
        "Name": "basic perspective",
        "Tree": {
          "Organization": this.tempData.organizations.test,
          "Tag": "subscriber",
          "Units": []
        },
        "Id": "3623475d-1f55-4392-8abc-8744d19770bf|7/9/2020 17:52:54",
        "OID": "3623475d-1f55-4392-8abc-8744d19770bf",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      }, 
      {
        "Name": "complex perspective",
        "Tree": {
          "Organization": this.tempData.organizations.Experian,
          "Tag": "Subscriber",
          "Units": [
            {
              "Organization": this.tempData.organizations.RealPage,
              "Tag": "Client",
              "Units": [
                {
                  "Organization": this.tempData.organizations.Accountants,
                  "Tag": "Customer",
                  "Units": []
                }, 
                {
                  "Organization": this.tempData.organizations.Durst,
                  "Tag": "Customer",
                  "Units": []
                }
              ]
            },{
              "Organization": this.tempData.organizations.Intuit,
              "Tag": "Customer",
              "Units": []
            }
        ]
        },
        "Id": "2223475d-1f55-4392-8abc-8744d19770bf|7/9/2020 17:52:54",
        "OID": "2223475d-1f55-4392-8abc-8744d19770bf",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      }
    ];
    return of( list );*/
    // return this.server.getAllPerspectives();
  }
  savePerspective(perspective: Perspective): Promise<Perspective> {
    return this.server.savePerspective(null, perspective).toPromise();
  }

  private tempData = {
    organizations: {
      test: {
        "ContactChannels": [],
        "Name": "subscriber org",
        "Perspective": "string",
        "Units": [],
        "Id": "4706b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "4706b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
      Experian: {
        "ContactChannels": [],
        "Name": "Experian",
        "Perspective": "string",
        "Units": [],
        "Id": "2706b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "2706b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
      RealPage: {
        "ContactChannels": [],
        "Name": "Real Page",
        "Perspective": "string",
        "Units": [],
        "Id": "2706b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "2706b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
      Accountants: {
        "ContactChannels": [],
        "Name": "ACCOUNTANTS ONE, INC",
        "Perspective": "string",
        "Units": [],
        "Id": "2706b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "2706b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
      Durst: {
        "ContactChannels": [],
        "Name": "The Durst Organization Inc",
        "Perspective": "string",
        "Units": [],
        "Id": "2706b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "2706b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
      Intuit: {
        "ContactChannels": [],
        "Name": "Intuit",
        "Perspective": "string",
        "Units": [],
        "Id": "2706b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "2706b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
      i4: {
        "ContactChannels": [],
        "Name": "i4 Recruitment Ltd",
        "Perspective": "string",
        "Units": [],
        "Id": "2406b2f4-547e-4c54-8482-c9c8e048b1dd|7/9/2020 17:52:54",
        "OID": "2406b2f4-547e-4c54-8482-c9c8e048b1dd",
        "SubscriberID": "07a345ef-d4a1-47e1-9798-dd4aa1423fc2",
        "OwnerID": "00000000-0000-0000-0000-000000000000",
        "Version": "2020-09-07T17:52:54"
      },
    }
  }
}