import { Component, OnInit, ViewChild } from '@angular/core';
// import Mediator from '../core-services/mediator/fixes.mediator';
import { Title } from '@angular/platform-browser';
import { Observable, of, Subscriber } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import Mediator from '../core-services/mediator/structures-adm.mediator';
import { Mediator as MediatorMarket } from '../core-services/mediator/market-utilities.mediator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddOrganizationDialogComponent } from '../add-organization-dialog/add-organization-dialog.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { Organization, Perspective } from '@smartobjx/smart.objx.models';
import { StructureBranchEditorCdkComponent } from '../structure-branch-editor-cdk/structure-branch-editor-cdk.component';
import { PermissionDrawerComponent } from '../permission-drawer/permission-drawer.component';
import * as _ from "lodash";
import { AuthService } from '../core-services/authentication/auth.service';
import { CustomValidator } from '../shared/Validation';
import { CdkDrag, CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

const isOfType = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);
const isFile = (name: string) => name.split('.').length > 1;

@Component({
  selector: 'structures-adm',
  templateUrl: './structures-adm.component.html',
  styleUrls: ['./structures-adm.component.scss']
})

export class StructuresAdmComponent implements OnInit {
  @ViewChild(StructureBranchEditorCdkComponent, { static: false }) child: StructureBranchEditorCdkComponent;
  selectedOrganization: Organization;
  @ViewChild(PermissionDrawerComponent, { static: false }) PermissionDrawerComponent: PermissionDrawerComponent;
  parentNode: any;

  constructor(private mediator: Mediator, private mediatorMarket: MediatorMarket, title: Title, public _dialog: MatDialog, private auth: AuthService) {
    this.subscribe(subscriber => {
      this.permissionListener = subscriber;
    }, (data: any) => {
      console.log(data);
      this.showPermissions = true;
    }
    );
  }

  ngOnInit() {
    this.initialLoad()
  }
  initialLoad(){
    setTimeout(() => {
      this.getOrganizations();
    }, 1000);
    this.getPerspectives();
  }
  evenPredicate(item: CdkDrag<number>) {
    // console.log(item.data);
    return true;
    // return item.data % 2 === 0;
  }
  drop(event: CdkDragDrop<{ title: string, poster: string }[]>, parent: any) {
    // moveItemInArray(this.movies, event.previousIndex, event.currentIndex);

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.updateStructure();
    } else {
      let item = event.previousContainer.data[event.previousIndex] as any;
      // console.log(event.previousContainer);
      if (event.previousContainer.element.nativeElement.classList.contains('drawer-list')) {
        if (!this.organizationsInStructure.filter(o => o.Name === item.Organization.Name).length) { // is not in the structure
          console.log('create copy');
          copyArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
        }
      } else {
        if (item != parent) {
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
          this.updateStructure();
        }
      }
    }
  }
  //#region methods
  private deepChangeColor(item: any, color: string, list: any[]) {
    let results = list.filter(o => o.Organization.Name === item.name);
    if (results.length) {
      list.forEach(o => o.Color = color);
    } else {
      list.forEach(o => {
        this.deepChangeColor(item, color, o.Units);
      });
    }
  }

  closePermission() {
    this.showPermissions = false;
    console.log(this.selectedPerspective.Tree)
    this.child.reloadPermissions(this.selectedOrganization)
  }
  changeColor(item: any) {
    const color = this.getRandomColor();
    const node = this.selectedPerspective.Tree;
    if (node.Organization.Name === item.name) {
      node.Color = color;
    } else {
      if (node.Partners) this.deepChangeColor(item, color, node.Partners);
      this.deepChangeColor(item, color, node.Units);
    }
    this.updateStructure();
  }
  editBranch(item: any) {
    const fn = (i: any) => {
    //  if (i.Organization.Name === item.name) {
       ;
     // } else {
        this.parentNode = i;
        i.Units.forEach(e => {
          fn(e);
        });
    //  }
    }
    const node = this.selectedPerspective.Tree;
    if (!_.isNil(node)) {
      this.selectedBranch = node
      fn(node);
    }
  }
  addPartnerToPerspective(partner: any) {
    const node = this.selectedPerspective.Tree;
    node.Partners = node.Partners || [];

    node.Partners.push({
      Organization: partner,
      Tag: 'Partner',
      Units: [],
      Color: node.Partners.length ? node.Partners[0].Color : undefined
    });
    this.updateStructure();
  }
  private deepUpdateTag(type: string, item: any, node: any) {
    if (node.Organization.Name === item.name) {
      node.Tag = type;
    } else {
      node.Units.forEach(o => {
        this.deepUpdateTag(type, item, o);
      });
    }
  }
  changeType(item: any) {
    this._dialog.open(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: true,
      data: {
        title: 'Change tag',
        // titleClass: 'warning',
        // matIcon: 'warning_amber',
        button1Text: 'Cancel',
        button2Text: 'Change',
        button1Color: 'primary',
        content: 'Change the tag for:\r\n <b>' + item.name + '</b>',
        useField: true,
        value: item.type
      }
    }).afterClosed().toPromise()
      .then(tag => {
        if (tag) {
          this.deepUpdateTag(tag, item, this.selectedPerspective.Tree);
          this.updateStructure();
        }
      });
  }
  //#region perspectives
  newPerspective(name: string) {
    let perspective = this.mediator.newPerspective(name);
    this.perspectives.push(name);
    // if(!this.selectedPerspective)
    //  this.selectedPerspective = perspective;
    // this.updateStructure();
    this.setPerspective(perspective, true);
    this.savePerspective()
  }

  configPerspective(el: any) {
    this.editingClientAPI = this.selectedPerspective.ClientAPI;
    this.openDialog(el, null).toPromise();
  }
  setClientAPI() {
    this.selectedPerspective.ClientAPI = this.editingClientAPI;
  }
  removePerspective(force: boolean) {
    if (!force && this.selectedPerspective.Tree) {
      this._dialog.open(SimpleDialogComponent, {
        panelClass: 'smart-objx',
        autoFocus: false,
        data: {
          title: 'Attention',
          titleClass: 'warning',
          matIcon: 'warning_amber',
          content: 'The perspective has items \r\nRemove anyway?',
          button1Text: 'Cancel',
          button2Text: 'OK',
          button1Color: 'primary'
        }
      }).afterClosed().toPromise()
        .then(action => {
          if (action) {
            this.removePerspective(true);
          }
        });
    } else {
      this.isLoading = true;

      this.mediator.deletePerspective(this.selectedPerspective).then(o => {
        this.isLoading = false;

        const i = this.perspectives.indexOf(this.selectedPerspective);
        this.perspectives.splice(i, 1);

        this.selectedPerspective = null;
        this.structure = [];
      });
    }
  }
  addPerspective() {
    const perspectives = this.perspectives;
    this._dialog.open(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: true,
      data: {
        title: 'New perspective',
        // titleClass: 'warning',
        // matIcon: 'warning_amber',
        button1Text: 'Close',
        button2Text: 'OK',
        button1Color: 'primary',
        useField: true,
        fieldPlaceholder: 'Perspective\' name',
        customValidation: (name: string) => {
          // console.log(name);
          return !name || !perspectives.filter(o => o.trim() === name.trim()).length;
        },
        customValidationMessage: 'Name already taken'
      }
    }).afterClosed().toPromise()
      .then(perspectiveName => {
        if (perspectiveName) {
          this.newPerspective(perspectiveName);
        }
      });
  }
  savePerspective() {
    this.isLoading = true;
    this.selectedPerspective.Version = new Date();
    this.selectedPerspective.Version = CustomValidator.fixDate(this.selectedPerspective.Version)
    this.mediator.savePerspective(this.selectedPerspective)
      .then(o => {
        const i = this.perspectives.indexOf(this.selectedPerspective);
        this.selectedPerspective = o;
        this.perspectives[i] = o;
        this.isLoading = false;
        this.setPerspectivebyName(this.selectedPerspective.Name)
      });
  }
  //#endregion
  deleteOrganization(organization?: any) {
    this._dialog.open(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: true,
      data: {
        title: 'Delete organization',
        titleClass: 'warning',
        matIcon: 'warning_amber',
        button1Text: 'Cancel',
        button2Text: 'Delete',
        button1Color: 'primary',
        content: 'Are you sure you want to delete: <b>' + organization.Name + '</b>?',
      }
    }).afterClosed().toPromise()
      .then(response => {
        if (response) {
          this.loadingOrganizations = true;
          let promise = this.mediator.deleteOrganization(organization)
          promise.then(() => {
            this.removeOrganization(organization);
            this.loadingOrganizations = false;
          });

          return promise;
        }
      });
  }
  addOrganizationFromDrawer(organization?: any, isPartner: boolean = false) {
    this.addNewOrganization(organization, isPartner).then(organization => {
      // this.loadingOrganizations = false;
      // this.addOrganization( organization ); // adds the new org to the list on the left.
      this.getOrganizations();
      const orgs = this.organizationsInStructure.filter(o => o.OID == organization.OID);
      if (orgs.length) {
        orgs[0].Name = organization.Name;
      }
    });
  }
  addNewOrganization(organization?: any, isPartner: boolean = false) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    let create = true;

    dialogConfig.data = { isPartner };

    if (organization) {
      organization.Version = new Date();
      organization.Version = CustomValidator.fixDate(organization.Version)
      dialogConfig.data.organization = organization;
      create = false;
    }

    //#region validations
    const organizations = this.organizations;
    const validationData = {
      customValidation: (name: string) => {
        return !name || !organizations.filter(o => o.Name.trim() === name.trim()).length;
      },
      customValidationMessage: 'Name already taken'
    }
    dialogConfig.data = Object.assign({}, validationData, dialogConfig.data);
    //#endregion

    return this.openDialog(AddOrganizationDialogComponent, dialogConfig)
      .pipe(concatMap(data => {
        if (data) {
          return this.saveOrganization(data)
        }
        return of(undefined);
      }
      )).toPromise();
  }

  private deepUpdate(child: any, parent: any, node: any, type: string, level: number) {
    if (node.Organization.Name === (parent.name || parent.Organization.Name)) { // temp fix
      // if(node.Organization.Name === parent.Organization.Name){
      node.Units.unshift(this.newPerspectiveElement(child, type, this.colorLevels.length > level ? this.colorLevels[level] : undefined));
    } else {
      node.Units.forEach(o => {
        this.deepUpdate(child, parent, o, type, level + 1);
      });
    }
  }
  addChildAs(item: any, type: string, parent: any) {
    if (parent) {
      this.deepUpdate(item, parent, this.selectedPerspective.Tree, type, 1);
    } else {
      this.selectedPerspective.Tree = this.newPerspectiveElement(item, type);
    }
    this.updateStructure();
  }
  addChild(item: Organization, parent: Organization) {
    let type = this.getTypeByParent(parent);
    this.addChildAs(item, type, parent ? { name: parent.Name } : undefined);
  }
  private getTypeByParent(parent: Organization) {
    const node = this.selectedPerspective.Tree;
    if (node) {
      const getType = (t: string) => t === 'Subscriber' ? 'Client' : '';
      const search = (list: any[]) => {
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (parent.Name === item.Organization.Name) {
            return getType(item.Tag);
          } else {
            const r = search(item.Units);
            if (r) {
              return r;
            }
          }
        }
      };
      return search([node]);
    } else {
      return 'Subscriber';
    }
  }
  private newPerspectiveElement(item: Organization, tag: string, color: string = undefined) {
    return {
      Organization: item,
      Tag: tag,
      Units: [],
      Color: color || this.getRandomColor()
    }
  }
  private getRandomColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }
  private simpleWarning(message: string, callback: () => void) {
    this._dialog.open(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: false,
      data: {
        title: 'Attention',
        titleClass: 'warning',
        matIcon: 'warning_amber',
        content: message,
        button1Text: 'Cancel',
        button2Text: 'OK',
        button1Color: 'primary'
      }
    }).afterClosed().toPromise()
      .then(action => {
        if (action) {
          callback();
        }
      });
  }
  removeFromGraph(item: any, force: boolean = false) {
    const node = this.selectedPerspective.Tree;
    if (node.Organization.Name === item.name) {
      if (!force && (node.Units.length || (node.Partners && node.Partners.length))) {
        this.simpleWarning('The item has children \r\nRemove anyway?', () => this.removeFromGraph(item, true))
      } else {
        this.selectedPerspective.Tree = null;
        this.structure = [];
      }
    } else {
      this.removeItemFromPartners(item);
      this.removeItem(item);
    }
  }
  private deepRemove(item: any, node: any, force: boolean = false) {
    let results = node.Units.filter(o => o.Organization.Name === item.name);
    if (results.length) {
      let i = node.Units.indexOf(results[0]);
      if (!force && node.Units[i].Units.length) {
        this.simpleWarning('The item has children \r\nRemove anyway?', () => {
          this.deepRemove(item, node, true);
          this.updateStructure();
        })
      } else {
        node.Units.splice(i, 1);
      }
    } else {
      node.Units.forEach(o => {
        this.deepRemove(item, o);
      });
    }
  }
  removeItem(item: any) {
    this.deepRemove(item, this.selectedPerspective.Tree);
    this.updateStructure();
  }
  removeItemFromPartners(item: any) {
    const node = this.selectedPerspective.Tree;
    if (node.Partners && node.Partners.length) {
      const results = node.Partners.filter(o => o.Organization.Name === item.name);
      if (results.length) {
        let i = node.Partners.indexOf(results[0]);
        node.Partners.splice(i, 1);
        this.updateStructure();
      }
    }
  }
  private saveOrganization(data: any) {
    this.loadingOrganizations = true;
    return this.mediator.saveOrganization(data)
  }
  private setOrganizations(organizations: any) {
    this.organizations = organizations.filter(o => !o.IsPartner);
    this.partners = organizations.filter(o => o.IsPartner);

    const notAdded = this.organizations.filter(o => !this.organizationsInStructure.filter(x => x.Name === o.Name).length);

    this.itemsAsPerspectiveElements = notAdded.map(o => {
      return {
        Organization: o,
        Tag: 'new',
        Units: []
      }
    });
  }
  private removeOrganization(organization: any) {
    const organizations = this.organizations.slice().concat(this.partners.slice()); // join orgs and partners in a single collection
    const index = organizations.indexOf(organization); // look for the record.
    organizations.splice(index, 1);
    this.setOrganizations(organizations); // replace old data
  }
  private addOrganization(organization: any) {
    const organizations = this.organizations.slice();
    organizations.push(organization);
    this.setOrganizations(organizations.concat(this.partners.slice()));
  }
  private getOrganizations() {
    this.mediator.getOrganizations()
      .subscribe(organizations => {
        this.setOrganizations(organizations);
        this.loadingOrganizations = false;
      }, (error: any) => {
        this.loadingOrganizations = false;
        // this.useCasesError = error.message;
        console.log(error);
      });
    ;
  }

  private getPerspectives() {
    this.perspectivesAreLoading = true;
    this.mediator.FindPerspectiveListNames()
      .subscribe(perspectives => {
        this.perspectivesAreLoading = false;
        this.perspectives = perspectives;


        this.mediator.FindPerspectiveWithName(this.perspectives[0]).subscribe(x => {
          this.setPerspective(x);
        })
        // debug

        // this.editBranch( { name: 'Experian' });
      }, (error: any) => {
        this.perspectivesAreLoading = false;
        console.log(error);
      });
  }

  setPerspectivebyName(name) {
    this.mediator.FindPerspectiveWithName(name).subscribe(x => {
      this.setPerspective(x);
    })
  }
  onVersions(record: any, date: Date, versionDates: any[]) {
    this.mediator.findPerspectiveOn(
      record,
      date//this.selectedIndex = -1
    ).subscribe((x) => this.setPerspective(x));
    //   this.updateSelected(record);
  }


  private openDialog(el: any, config: any) {
    const dialogRef = this._dialog.open(el, config);
    return dialogRef.afterClosed();
  }
  private subscribe(register: (subscriber: Subscriber<any>) => void, next: (data: any) => void) {
    const observable = new Observable(register);

    observable.subscribe({
      next,
      error: e => {
        console.error(e);
      },
      complete: () => console.log('finished')
    });
  }

  private getAsStructure(tree: any) {
    const { Organization, Tag, Units, Partners, Color } = tree;
    return {
      name: Organization.Name,
      type: Tag,
      permissions: {
        // hide: true,
      },
      partners: Partners && Partners.length ? Partners.map(o => this.getAsStructure(o)) : [],
      children: Units.map(o => this.getAsStructure(o)),
      color: Color ? Color : Tag === 'Subscriber' ? null : Tag === 'Client' ? '#FF0C3E' : Tag === 'Partner' ? '#FFA000' : '#1e5589',
    };
  }
  private updateStructure() {
    if (this.selectedPerspective && this.selectedPerspective.Tree) {
      this.structure = [this.getAsStructure(this.selectedPerspective.Tree)];
    } else {
      this.structure = [];
    }
  }
  private setPerspective(perspective: any, isNewPerspective: boolean = false) {
    if (this.child) {
      this.child.closeEditBranch();
    }
    this.selectedPerspective = perspective;
    this.updateStructure();
    //this.isLoading = true
    if (!isNewPerspective && !_.isNil(perspective.OID)) {
      setTimeout(() => {
        this.editBranch({ name: this.auth.userName });
        this.child.editBranch(this.selectedBranch)
        this.mediator.rootlevel = this.selectedBranch
      }, 200);
    }
    // const organizations = this.getOrganizationsFromStructure(perspective.Tree);
    // console.log(this.organizations);
    // console.log(organizations);
    // this.availableOrganizations = this.organizations.filter(o => !~organizations.map(o => o.Name).indexOf(o.Name) );
  }
  createOrganizationThenAdd(parent: any, isPartner: boolean = false) {
    this.addNewOrganization(null, isPartner).then(organization => {
      if (organization) {
        this.loadingOrganizations = false;
        this.addOrganization(organization); // adds the new org to the list on the left.
        if (isPartner) {
          this.addPartnerToPerspective(organization);
        } else {
          this.addChild(organization, ({ Name: parent.Organization.Name }) as Organization);
        }
      }
    })
  }
  permissionFor(item: any) {
    if (!_.isNil(item) && !_.isNil(item.Organization)) {
      this.mediatorMarket.GetACLsForAccessee(item.Organization.OID, this.selectedPerspective.OID).subscribe(enableACL => {
        this.showPermissions = true;
        let acesList = {}
        if (!_.isNil(enableACL) && enableACL.length > 0) {
          acesList = enableACL.map(x => {
            return {
              ...x, Disabled: x.Accessor.OID == item.Organization.OID, Show: true, Access: x.Accessor.OID == item.Organization.OID ? true : x.Access
            }
          })
        }

        this.PermissionDrawerComponent.reloadOrganizations(acesList);
        this.selectedOrganization = item
      })
    }


  }
  //#endregion



  permissionListener: Subscriber<any>;
  get zoom(): string {
    // return 'scale(2);';
    return `scale(${this.zoomLevel})`;
  }
  isLoading: boolean;
  perspectivesAreLoading: boolean;
  showPermissions: boolean = false;
  loadingOrganizations: boolean = true;
  organizations: Organization[];
  partners: Organization[];
  perspectives: any[];
  selectedPerspective: any;
  selectedBranch: any; // selectedPerspective.Tree
  itemsAsPerspectiveElements: any[] = [];
  drawerState: boolean = true;
  editingClientAPI: string;

  private getOrganizationsFromStructure(el: any): Organization[] {
    return [].concat.apply([el.Organization], el.Units.map(o => this.getOrganizationsFromStructure(o)));
  }
  get partnersInStructure(): Organization[] {
    if (!this.selectedPerspective || !this.selectedPerspective.Tree || !this.selectedPerspective.Tree.Partners) return [];
    return this.selectedPerspective.Tree.Partners.map(o => o.Organization);
  }
  get organizationsInStructure(): Organization[] {
    if (!this.selectedPerspective || !this.selectedPerspective.Tree) return [];
    const organizations = this.getOrganizationsFromStructure(this.selectedPerspective.Tree);
    return organizations;
  }
  get availableOrganizations(): any[] {
    if (!this.selectedPerspective || !this.organizations) return [];
    const organizations = this.organizationsInStructure;
    let list = this.organizations.filter(o => !~organizations.map(x => x.Name).indexOf(o.Name));
    // console.log(list);
    return list;
  }
  get availablePartners(): any[] {
    if (!this.selectedPerspective || !this.partners) return [];
    const partners = this.partnersInStructure;
    let list = this.partners.filter(o => !~partners.map(x => x.Name).indexOf(o.Name));
    // console.log(list);
    return list;
  }
  get customersForMarket(): any {
    const organizations = typeof this.organizations != 'undefined' ? this.organizations.map(o => { return { Name: o.Name, OID: o.OID, Checked: false, Show: true, Disabled: false } }) : [];
    return organizations;
  }
  structure: any[] = [];
  structure1: any[] = [{
    name: 'Experian',
    type: 'Subscriber',
    permissions: {
      hide: true,
    },
    partners: [{
      name: 'Sterling',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Good Hire',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Accurate Now',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }],
    children: [{
      name: 'Real Page',
      type: 'Client',
      color: '#FF0C3E',
      permissions: {
        manageDisabled: false,
        viewDisabled: true,
      },
      children: [{
        name: 'Aporeto',
        type: 'Customer',
        color: '#33B700',
        permissions: {
          manageDisabled: true
        },
        children: []
      }]
    }, {
      name: 'Intuit',
      type: 'Customer',
      color: '#FF0C3E',
      permissions: {
        manageDisabled: true
      },
      children: [{
        name: 'Intuit West',
        type: 'Customer',
        color: '#33B700',
        permissions: {
          disabled: false
        },
        children: []
      }, {
        name: 'Intuit International',
        type: 'Customer',
        color: '#33B700',
        // children: []
        permissions: {
          disabled: false
        },
        children: [{
          name: 'Americas',
          type: 'Customer',
          color: '#33B700',
          permissions: {},
          children: []
        }, {
          name: 'Europa',
          type: 'Customer',
          color: '#33B700',
          permissions: {},
          children: []
        }, {
          name: 'Asia',
          type: 'Customer',
          color: '#33B700',
          permissions: {},
          children: []
        }]
      }, {
        name: 'Intuit East',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }]
      // }, {
      //     name: 'National Grid',
      //     type: 'Client',
      //     color: '#FF0C3E',
      //     permissions: {},
      //     children: []
    }]
  }];
  structure2: any[] = [{
    name: 'Intuit',
    type: 'Customer',
    color: '#FF0C3E',
    permissions: {
      manageDisabled: true
    },
    partners: [{
      name: 'Sterling',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Good Hire',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Accurate Now',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }],
    children: [{
      name: 'Intuit West',
      type: 'Customer',
      color: '#33B700',
      permissions: {
        disabled: false
      },
      children: []
    }, {
      name: 'Intuit International',
      type: 'Customer',
      color: '#33B700',
      // children: []
      permissions: {
        disabled: false
      },
      children: [{
        name: 'Americas',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }, {
        name: 'Europa',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }, {
        name: 'Asia',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }]
    }, {
      name: 'Intuit East',
      type: 'Customer',
      color: '#33B700',
      permissions: {},
      children: []
    }]
  }];
  zoomLevel: number = 1;

  public data: any[] = [
    {
      text: 'Furniture', items: [
        { text: 'Tables & Chairs' },
        { text: 'Sofas' },
        { text: 'Occasional Furniture' }
      ]
    },
    {
      text: 'Decor', items: [
        { text: 'Bed Linen' },
        { text: 'Curtains & Blinds' },
        { text: 'Carpets' }
      ]
    }
  ];

  draggingItem: any;

  private colorLevelChecker(level: any[], list: string[]) {
    const merged = [].concat.apply([], level.map(o => o.children));
    if (merged.length) {
      list.push(merged[0].color);
      const withChildren = merged.filter(o => o.children.length);
      if (withChildren.length) {
        this.colorLevelChecker(withChildren, list);
      }
    }
  }
  get colorLevels(): string[] {
    let list = ['red', 'green', 'blue'];
    if (this.structure.length) {
      const subscriber = this.structure[0];
      list = [subscriber.color || 'blue'];

      this.colorLevelChecker(this.structure, list);
    }
    return list;
  }
  get colorLevelsBySelectedBranch(): string[] {
    const branch = this.selectedBranch;
    if (!branch) return this.colorLevels;


    const countLevel = (list: any, count: number = 0) => {
      return !!~list.indexOf(branch) ? count : countLevel([].concat.apply([], list.map(o => o.Units)), count + 1);
    };

    let level = countLevel([this.selectedPerspective.Tree]);

    return this.colorLevels.slice(level);
  }

  test(ev: any) {
    console.log(ev);
  }
  //#region angular drag and drop API

  //#endregion
}
