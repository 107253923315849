import { Injectable } from '@angular/core';
import { MarketService, StructuresService } from '@smartobjx/smart.connectors';
import { AccessControlEntry, Organization, Perspective } from '@smartobjx/smart.objx.models';
import { AuthService } from '../authentication/auth.service';
import { Observable, of } from 'rxjs';
import * as Guid from 'uuid/v4';
export function factory() {
  return (_server: MarketService, _auth: AuthService): Mediator => {
    return new Mediator(_server, _auth);
  };
}

@Injectable({
  providedIn: 'root'
})
export  class Mediator {

  constructor(_server: any, _auth: AuthService) {
    this.server = _server; // fix compile duplicate 
  }
  private server: MarketService;


 //UpdateACLEntries(listAcls: Array<AccessControlEntry>): Observable<Array<AccessControlEntry>> {
 //  return this.server.updateACLEntries(listAcls);
 //}
  SaveACLEntry(acls: AccessControlEntry): Observable<any> {
    return this.server.saveACLEntry(null,acls);
  }
  GetACLsForAccessee(owner:Guid,perspective:Guid): Observable<Array<AccessControlEntry>> {
    return this.server.getACLsForAccesse(owner, perspective);
  }
  GetACLbyAccesor(owner:Guid): Observable<any> {
    return this.server.getACLbyAccesor(owner);
  }

}