<!-- <mat-drawer #drawer mode="side" [opened]="true"> -->
<mat-card class="content">
  <button mat-icon-button class="close-button" (click)="close.emit()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-title class="title">
    <span>PERMISSIONS</span>
  </mat-card-title>
  <div class="top-info">
   
      <div class="info">Choose who you want to share your data with:</div>
      
    <div class="filter">
      <mat-form-field>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [(ngModel)]="currentSearch" (keyup)="searchPreview( $event, searchInput )"
          placeholder="Search..." #searchInput />
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="search( searchInput )">Search</button>
      <button mat-button [matMenuTriggerFor]="menu" class="link">
        <mat-icon>tune</mat-icon> Filter
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="selectFilter('All items')" mat-menu-item>All items</button>
        <button (click)="selectFilter('Only selected items')" mat-menu-item>Only selected items</button>
        <button (click)="selectFilter('Only unselected items')" mat-menu-item>Only unselected items</button>
      </mat-menu>
    </div>
    <div class="applied-filters">
      <span class="text">{{ appliedFilter | uppercase }}</span>
      <button mat-icon-button (click)="clearSearch()" matTooltip="remove filter">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="search-chips" *ngIf="searchChips.length">
      <mat-chip-list #chipList>
        <span>Search results for:&nbsp;</span>
        <mat-chip *ngFor="let chip of searchChips" (removed)="removeChip(chip)" (click)="removeChip(chip)">
          {{ chip }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  
  </div>
  <mat-card-content>
    <div class="customers">
      <div class="subtitle " style="display: flex;">
        <span>CUSTOMERS</span>
        <div style="margin-left:10px"  *ngIf="loading" >
          <mat-spinner [diameter]="27"></mat-spinner>
        </div>
      </div>
   
      <div *ngFor="let item of aclList; index as i">
        <mat-checkbox *ngIf="item.Show" [disabled]="item.Disabled" name="{{i}}-check-permision"
          [(ngModel)]="item.Access" (change)="changePermision(item)" color="accent">{{ item.Accessor.Name }}
        </mat-checkbox>
      </div>
    </div>
  </mat-card-content>
</mat-card>
