<mat-card-title class="subtitle">
  <button mat-icon-button color="primary" class="control" (click)="toggleList()">
    <mat-icon *ngIf="!collapsed">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="collapsed">keyboard_arrow_right</mat-icon>
  </button>
  <span>
    <ng-container *ngIf="draggingItem"> (dragging) </ng-container>
    {{ type | uppercase }} | <b>{{ isLoading ? 0 : items.length }}</b>
  </span>
  
  <div class="controls">
    <button mat-button [matMenuTriggerFor]="sortBy" class="link">
      <mat-icon>tune</mat-icon> Sort by
    </button>
    <mat-menu #sortBy [overlapTrigger]="false">
      <div mat-menu-item><mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="filterIncluded">Included in Structure</mat-checkbox></div>
      <div mat-menu-item><mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="filterNotIncluded">Not included in Structure</mat-checkbox></div>
    </mat-menu>
    <button mat-icon-button  utton color="primary" class="add" (click)="add.emit()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</mat-card-title>
<div class="content drawer-list" *ngIf="!collapsed"
>
  <mat-progress-bar *ngIf="isLoading; else content" mode="indeterminate"></mat-progress-bar>
</div>

<ng-template #content>
  <span *ngIf="items.length === 0; else list">You haven´t added any {{ type }} yet</span>
</ng-template>

<ng-template #list>
  <ng-container *ngIf="asPartner">
    <div *ngFor="let item of items" class="record">
      <ng-container *ngTemplateOutlet="itemRow;context:{item: item}"></ng-container>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!asPartner">
    <ng-container *ngTemplateOutlet="dragDropList;context:{items: items, dragItem: dragged}"></ng-container>
  </ng-container>

  <ng-container *ngIf="itemsOut.length">
    <ng-container *ngTemplateOutlet="dragDropList;context:{items: itemsOut, dragItem: dragged, separator: true}"></ng-container>
  </ng-container>
</ng-template>



<ng-template #dragDropList let-items="items" let-dragItem="dragItem" let-separator="separator">
  <div *ngFor="let item of items; index as i" [class.movable]="isNotInExtraList( item )"
  >
    <ng-container *ngIf="item === dragItem;">
      <div class="record" [class.first]="separator && i == 0">
        <ng-container *ngTemplateOutlet="itemRowMockup;context:{item: item, movable: isNotInExtraList( item )}"></ng-container>
      </div>
    </ng-container>
    <div cdkDropList [cdkDropListData]="items">
      <!-- record cdk-drag mat-card cdk-drag-preview -->
      <mat-card
        *ngIf="isNotInExtraList( item ); else disabledItem"
        class="record" [class.first]="separator && i == 0"
        cdkDrag [cdkDragData]="{ item: item, isNew: true }"
        (cdkDragStarted)="cdkDragStarted( $event, item )" 
        (cdkDragReleased)="cdkDragReleased( $event )"
        [cdkDragDisabled]="false"
        >
        <ng-container *ngTemplateOutlet="itemRow;context:{item: item, movable: isNotInExtraList( item )}"></ng-container>
        <div class="drag-placeholder" *cdkDragPlaceholder></div>
        <!-- <mat-card *cdkDragPreview class="preview-card"
          [id]="item.OID + '_preview'"
        >
          <mat-card-title>{{item.Name}}</mat-card-title>
          <mat-card-subtitle>New</mat-card-subtitle>
        </mat-card> -->
      </mat-card>
      <ng-template #disabledItem>
        <div class="record" [class.first]="separator && i == 0"
          cdkDrag [cdkDragData]="{ item: item, isNew: true }"
          [cdkDragDisabled]="true"
          >
          <ng-container *ngTemplateOutlet="itemRow;context:{item: item, movable: isNotInExtraList( item )}"></ng-container>
        </div>
      </ng-template>
    </div>

  </div>
</ng-template>

<ng-template #itemRow let-item="item" let-movable="movable">
  <div class="name"> <!-- [class.draggable]="isNotInExtraList( item )" -->
    {{ item.Name }}
  </div>
  <div class="options">
    <button mat-icon-button color="primary" matTooltip="options" 
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
    <ng-container *ngIf="!disableOptions && isNotInExtraList( item )">
      <button *ngIf="asPartner || !extraList.length" mat-icon-button color="primary" matTooltip="Add to perspective" (click)="addChild.emit( { item: item, type: 'Subscriber' } )">
        <mat-icon>forward</mat-icon>
      </button>
      <button *ngIf="!asPartner && extraList.length" mat-icon-button color="primary" matTooltip="Add to..." [matMenuTriggerFor]="menuAddTo">
        <mat-icon>forward</mat-icon>
      </button>
    </ng-container>
    <button *ngIf="!isNotInExtraList( item )" mat-icon-button color="primary" matTooltip="Remove from graph" (click)="removeItem.emit( item )">
      <mat-icon>clear</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onEdit.emit( item )">Edit</button>
      <button *ngIf="isNotInExtraLists( item )" mat-menu-item (click)="onDelete.emit( item )">Delete</button>
    </mat-menu>
    
    <mat-menu #menuAddTo="matMenu" (closed)="closeMenu();">
      <ng-container *ngIf="extraList.length > 1">
        <input matInput mat-menu-item #filterInput class="fix-search"
          (click)="$event.stopPropagation()"
          (blur)="filterInput.focus()"
          (keyup)="filterList($event)"
          placeholder="Search..."
        /> <!-- (blur)="filterInput.focus()" https://github.com/angular/components/issues/7973 -->
        <mat-divider></mat-divider>
      </ng-container>

      <button mat-menu-item *ngFor="let extraItem of extraListFiltered; let firstItem = first;"
        (click)="addChild.emit( { item: item, parent: extraItem } )"
      >{{ extraItem.Name }}</button>
    </mat-menu>
  </div>
</ng-template>

<ng-template #itemRowMockup let-item="item" let-movable="movable">
  <div class="name" [draggable]="movable">
    {{ item.Name }} 
  </div>
  <div class="options">
    <button mat-icon-button color="primary" matTooltip="options">
      <mat-icon>more_horiz</mat-icon>
    </button>
    <ng-container *ngIf="!disableOptions && isNotInExtraList( item )">
      <button *ngIf="asPartner || !extraList.length" mat-icon-button color="primary" matTooltip="Add to perspective">
        <mat-icon>forward</mat-icon>
      </button>
      <button *ngIf="!asPartner && extraList.length" mat-icon-button color="primary" matTooltip="Add to...">
        <mat-icon>forward</mat-icon>
      </button>
    </ng-container>
    <button *ngIf="!isNotInExtraList( item )" mat-icon-button color="primary" matTooltip="Remove from graph">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</ng-template>
