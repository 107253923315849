<mat-card class="center">
  <mat-progress-bar mode="indeterminate" class="card-top"
    [class.hidden]="!isLoading "></mat-progress-bar>
  <mat-card-content>
    <img src="assets/microsoft.png" style="width: 78%;">
    <br>
    <div>Please enter the name of the company, to complete the registration</div>
    <br>
    <form [formGroup]="form">
      <mat-card-content>

        <mat-form-field class="custom">
          <input matInput placeholder="Name" formControlName="name" #nameInput />
        </mat-form-field>
      </mat-card-content>
      <button mat-raised-button color="primary" (click)="register()"
        [disabled]="this.form.invalid || this.successRegister || isLoading">Register</button>
      <div *ngIf="this.successRegister" >you have registered successfully</div>

    </form>




  </mat-card-content>
</mat-card>