import StructuresAdmMediator, { factory as StructuresAdmMediatorFactory } from './structures-adm.mediator';
import LoginPortalMediator, { factory as LoginPortalMediatorFactory } from './login-portal.mediator';
import SettingsMediator, { factory as SettingsMediatorFactory } from './login-portal.mediator';
import ConfigVersionMediator, { factory as ConfigVersionMediatorFactory } from './config-versions.mediator';

import FixesMediator, { factory as FixesMediatorFactory } from './fixes.mediator';
import { Mediator as MarketMediator ,factory as MarketMediatorFactory } from './market-utilities.mediator';

import { MarketService, SettingsService, StructuresService } from '@smartobjx/smart.connectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../authentication/auth.service';

export function combined() {
  return [

    {
      provide: StructuresAdmMediator,
      useFactory: StructuresAdmMediatorFactory(),
      deps: [StructuresService,MarketService, AuthService]
    },
    {
      provide: LoginPortalMediator,
      useFactory: LoginPortalMediatorFactory(),
      deps: [StructuresService,SettingsService,AuthService]
    },
    {
      provide: FixesMediator,
      useFactory: FixesMediatorFactory(),
      deps: [StructuresService, AuthService]
    },
    {
      provide: MarketMediator,
      useFactory: MarketMediatorFactory(),
      deps: [MarketService, AuthService]
    },
    {
      provide: ConfigVersionMediator,
      useFactory: ConfigVersionMediatorFactory(),
      deps: [StructuresService , AuthService]
    },
  
  ];
}
